import { AxiosInstance } from 'axios';
import { auth0Client } from '../contexts/auth0-context';

const idToken = async () => {
  const idTokenClaims = await auth0Client?.getIdTokenClaims({});

  if (idTokenClaims) {
    return idTokenClaims?.__raw;
  }

  return null;
};

const requestHandler = async request => {
  const token = await idToken();

  if (!token) {
    return Promise.reject(new Error('Authentication token is not present'));
  }

  request.headers.Authorization = `Bearer ${token}`;
  request.headers.Accept = 'application/json';
  return request;
};

export const includeRequestHeaders = (apiClient: AxiosInstance) => {
  apiClient.interceptors.request.use(
    request => requestHandler(request),
    error => {
      Promise.reject(error);
    },
  );
};
